import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FocusFirstInvalidInputDirective } from './directives/focus-first-invalid-input.directive';
import { DateHelperService } from './services/date-helper.service';
import { FirebaseStorageService } from './services/firebase-storage.service';
import { FirestoreHelperService } from './services/firestore-helper.service';

@NgModule({ declarations: [
        FocusFirstInvalidInputDirective
    ],
    exports: [
        FocusFirstInvalidInputDirective
    ], imports: [CommonModule,
        MatSnackBarModule], providers: [
        FirestoreHelperService,
        DateHelperService,
        FirebaseStorageService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
